import { Container, Divider, Link, Stack, Typography } from "@mui/material";
import React from "react";
import Gap from './Gap'

const BottomNav = props => {
    const tStyle = { fontSize: 13, color: '#999', textDecoration: 'none' }
    return (
        <>
            <Divider />
            <Gap height={32} />
            <Container maxWidth="md">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body1" sx={tStyle} component="a">
                        微信：xiaoxiong2466
                    </Typography>
                    <Typography variant="body1" sx={tStyle} component="a" href="mailto:1120348592@qq.com">
                        邮箱：1120348592@qq.com
                    </Typography>
                </Stack>
                <Gap height="8" />
                <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                    <Link sx={tStyle} href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43100202000378" target="_blank" rel="noopener">
                        <Stack direction="row" alignItems="center">
                            <img src="/logo_gongan.png" width={18} height={18} alt="logo_gongan" />
                            <Typography variant="body1" sx={tStyle}>
                                湘公网安备43100202000378号
                            </Typography>
                        </Stack>
                    </Link>
                    <Typography variant="body1" sx={tStyle} component="a" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener">
                        湘ICP备2022007274号
                    </Typography>
                    <Typography variant="body1" sx={tStyle} component="a">
                        {`©${new Date().getFullYear()}雄熊工作室`}
                    </Typography>
                </Stack>
            </Container>
            <Gap height={32} />
        </>
    )
}

export default BottomNav