import { AppBar, Container, Grid, Link, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import BottomNav from "../components/BottomNav";
import Gap from "../components/Gap";
import WebIcon from '@mui/icons-material/Web';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QrCodeIcon from '@mui/icons-material/QrCode';

const index = props => {
    const cardS = { background: 'white', border: '2px solid #eee' }
    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <Container maxWidth="md">
                        <Typography variant="h5" component="a" href="/" sx={{
                            fontFamily: 'monospace',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                            雄熊工作室
                        </Typography>
                    </Container>
                </Toolbar>
            </AppBar>
            <div style={{ background: '#f8f8f8', minHeight: '80vh' }}>
                <Container maxWidth="md">
                    <Gap height={80} />
                    <Stack alignItems="center" >
                        <Typography sx={{ fontSize: { md: 56, xs: 29 } }}>
                            SCOPE OF SERVICES
                        </Typography>
                        <Typography sx={{ fontSize: { md: 56, xs: 29 } }}>
                            服务范围
                        </Typography>
                    </Stack>
                    <Gap height={56} />
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={12} sm={6}>
                            <Stack alignItems="center" sx={cardS} >
                                <Gap height={24} />
                                <WebIcon sx={{ fontSize: 100 }} color="action" />
                                <Gap height={30} />
                                <Typography variant="h6" sx={{ color: '#777' }}>
                                    Website Building
                                </Typography>
                                <Gap height={60} />
                                <Typography variant="h5">
                                    网站建设
                                </Typography>
                                <Gap height={32} />
                            </Stack>
                        </Grid>

                        <Grid item md={3} xs={12} sm={6}>
                            <Stack alignItems="center" sx={cardS} >
                                <Gap height={24} />
                                <DesignServicesIcon sx={{ fontSize: 100 }} color="action" />
                                <Gap height={30} />
                                <Typography variant="h6" sx={{ color: '#777' }}>
                                    Custom Made
                                </Typography>
                                <Gap height={60} />
                                <Typography variant="h5">
                                    个性定制
                                </Typography>
                                <Gap height={32} />
                            </Stack>
                        </Grid>

                        <Grid item md={3} xs={12} sm={6}>
                            <Stack alignItems="center" sx={cardS} >
                                <Gap height={24} />
                                <PhoneAndroidIcon sx={{ fontSize: 100 }} color="action" />
                                <Gap height={30} />
                                <Typography variant="h6" sx={{ color: '#777' }}>
                                    Mobile Appliction
                                </Typography>
                                <Gap height={60} />
                                <Typography variant="h5">
                                    移动App
                                </Typography>
                                <Gap height={32} />
                            </Stack>
                        </Grid>

                        <Grid item md={3} xs={12} sm={6}>
                            <Stack alignItems="center" sx={cardS} >
                                <Gap height={24} />
                                <QrCodeIcon sx={{ fontSize: 100 }} color="action" />
                                <Gap height={30} />
                                <Typography variant="h6" sx={{ color: '#777' }}>
                                    Mini Program
                                </Typography>
                                <Gap height={60} />
                                <Typography variant="h5">
                                    小程序
                                </Typography>
                                <Gap height={32} />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Gap height={80} />
                    <Typography variant="h5" color="primary">
                        产品
                    </Typography>
                    <Gap height={24} />
                    <Grid container spacing={4}>
                        <Grid item md={3} xs={12}>
                            <Link rel="noopener" href="https://cai.xiongxstudio.com" target="_blank" underline="none" >
                                <Stack alignItems="center">
                                    <img src="/wxmini_qrcode_thumb.jpg" alt="wxmini_qrcode_thumb" width={192} height={192} />
                                    <Gap height={24} />
                                    <Typography variant="subtitle1" fontSize={18} color="#333">
                                        菜通宝
                                    </Typography>
                                </Stack>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
                <Gap height={32} />
            </div>
            <BottomNav />
        </>

    )
}

export default index